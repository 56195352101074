import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    component: () => import("../views/layout/Layout.vue"),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/Home')
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy')
      },
      {
        path: '/terms-condition',
        name: 'TermsAndCondition',
        component: () => import('../views/TermsAndCondition')
      },
      {
        path: '/refund-and-cancellation',
        name: 'RefundAndCancellation',
        component: () => import('../views/RefundAndCancellation')
      },
      {
        path: '/game-rules',
        name: 'GameRules',
        component: () => import('../views/GameRules')
      }
    ]
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFoundComponent",
    component: () => import("../views/errors/NotFoundComponent.vue"),
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  const layoutId = document.getElementById("mainbody");

  if (savedPosition) {
    return savedPosition; // Return saved position for browser back/forward navigation
  }

  if (to.hash) {
    // Handle scrolling to an element with the id matching the hash
    const element = document.querySelector(to.hash);
    if (element) {
      return new Promise((resolve) => {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          resolve({ left: 0, top: element.offsetTop });
        }, 300); // Delay to ensure the DOM is updated
      });
    }
  }

  // Default scroll behavior
  if (layoutId) {
    layoutId.scrollTop = 0;
    return { left: 0, top: 0 };
  }

  return { left: 0, top: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
});

export default router
